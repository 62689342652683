export class DeptFieldsInternships {
  studentClass: boolean; // Studiengang und Klasse dasselbe?
  mentorNameComp: boolean;
  trainerNameComp: boolean;
  registrationDate: boolean;
  filterZeitplanungAbgabeQualiSoll: boolean;
  sozialeArbeit: boolean;
  sozialeArbeitButtons: boolean;
  workload: boolean;
  subjectAreaName: boolean;
  inkusivArchivierte: boolean;
  setZielgespraecheOk: boolean;
  publizierenPlanung: boolean;
  getVorlageArbeitsundAusbildungsverhaeltnis: boolean;
}

export const deptFieldsInternshipsDefault: DeptFieldsInternships = {
  studentClass: false,
  mentorNameComp: false,
  trainerNameComp: false,
  registrationDate: false,
  filterZeitplanungAbgabeQualiSoll: false,
  sozialeArbeit: false,
  sozialeArbeitButtons: false,
  workload: false,
  subjectAreaName: false,
  inkusivArchivierte: false,
  setZielgespraecheOk: false,
  publizierenPlanung: false,
  getVorlageArbeitsundAusbildungsverhaeltnis: false,
};
export const deptFieldsInternshipsShowAll: DeptFieldsInternships = {
  studentClass: true,
  mentorNameComp: true,
  trainerNameComp: true,
  registrationDate: true,
  filterZeitplanungAbgabeQualiSoll: true,
  sozialeArbeit: true,
  sozialeArbeitButtons: true,
  workload: true,
  subjectAreaName: true,
  inkusivArchivierte: true,
  setZielgespraecheOk: true,
  publizierenPlanung: true,
  getVorlageArbeitsundAusbildungsverhaeltnis: true,
};
export const deptFieldsInternshipsIER: DeptFieldsInternships = {
  studentClass: true,
  mentorNameComp: false,
  trainerNameComp: false,
  registrationDate: false,
  filterZeitplanungAbgabeQualiSoll: false,
  sozialeArbeit: false,
  sozialeArbeitButtons: false,
  workload: false,
  subjectAreaName: true,
  inkusivArchivierte: true,
  setZielgespraecheOk: false,
  publizierenPlanung: true,
  getVorlageArbeitsundAusbildungsverhaeltnis: false,
};
export const deptFieldsInternshipsIHB: DeptFieldsInternships = {
  studentClass: true,
  mentorNameComp: false,
  trainerNameComp: false,
  registrationDate: false,
  filterZeitplanungAbgabeQualiSoll: true,
  sozialeArbeit: false,
  sozialeArbeitButtons: false,
  workload: true,
  subjectAreaName: true,
  inkusivArchivierte: true,
  setZielgespraecheOk: false,
  publizierenPlanung: true,
  getVorlageArbeitsundAusbildungsverhaeltnis: false,
};
export const deptFieldsInternshipsIPF: DeptFieldsInternships = {
  studentClass: true,
  mentorNameComp: false,
  trainerNameComp: false,
  registrationDate: false,
  filterZeitplanungAbgabeQualiSoll: true,
  sozialeArbeit: false,
  sozialeArbeitButtons: false,
  workload: false,
  subjectAreaName: false,
  inkusivArchivierte: true,
  setZielgespraecheOk: false,
  publizierenPlanung: true,
  getVorlageArbeitsundAusbildungsverhaeltnis: true,
};
export const deptFieldsInternshipsIPT: DeptFieldsInternships = {
  studentClass: true,
  mentorNameComp: false,
  trainerNameComp: false,
  registrationDate: false,
  filterZeitplanungAbgabeQualiSoll: false,
  sozialeArbeit: false,
  sozialeArbeitButtons: false,
  workload: false,
  subjectAreaName: true,
  inkusivArchivierte: true,
  setZielgespraecheOk: false,
  publizierenPlanung: false,
  getVorlageArbeitsundAusbildungsverhaeltnis: false,
};
export const deptFieldsInternshipsS: DeptFieldsInternships = {
  studentClass: false,
  mentorNameComp: true,
  trainerNameComp: true,
  registrationDate: true,
  filterZeitplanungAbgabeQualiSoll: false,
  sozialeArbeit: true,
  sozialeArbeitButtons: true,
  workload: false,
  subjectAreaName: false,
  inkusivArchivierte: true,
  setZielgespraecheOk: true,
  publizierenPlanung: true,
  getVorlageArbeitsundAusbildungsverhaeltnis: false,
};
export const deptFieldsInternshipsIPH: DeptFieldsInternships = {
  studentClass: false,
  mentorNameComp: true,
  trainerNameComp: true,
  registrationDate: false,
  filterZeitplanungAbgabeQualiSoll: false,
  sozialeArbeit: false,
  sozialeArbeitButtons: false,
  workload: true,
  subjectAreaName: false,
  inkusivArchivierte: true,
  setZielgespraecheOk: false,
  publizierenPlanung: false,
  getVorlageArbeitsundAusbildungsverhaeltnis: true,
};
export const deptFieldsInternshipsN: DeptFieldsInternships = {
  studentClass: false,
  mentorNameComp: false,
  trainerNameComp: false,
  registrationDate: false,
  filterZeitplanungAbgabeQualiSoll: false,
  sozialeArbeit: false,
  sozialeArbeitButtons: false,
  workload: false,
  subjectAreaName: false,
  inkusivArchivierte: true,
  setZielgespraecheOk: false,
  publizierenPlanung: true,
  getVorlageArbeitsundAusbildungsverhaeltnis: false,
};

export class DeptFieldsInternshipDetail {
  workflowstatus: boolean;
  selectedStudyState: boolean;
  cancelledInterrupted: boolean;
  subjectArea: boolean;
  zeitplanungButton: boolean;
  studentSpezialfall: boolean;
  studierendenbegleitende: boolean;
  orgChirAndCoRadioButtons: boolean;
  orgWochenbettabteilung: boolean;
  orgAkutsomatikRadioButtons: boolean;
  trainer: boolean;
  trainer1and2: boolean;
  orgFachforum: boolean;
  infoPraktikumsperiode: boolean;
  infoPraktikumsbeginn: boolean;
  infoVertragsbeginn: boolean;
  infoPraktikumsBeginnEnde: boolean;
  infoVertragsende: boolean;
  infoAnzahlStunden: boolean;
  infoPraktikumsende: boolean;
  infoAnzahlArbeitstage: boolean;
  infoArbeitspensum: boolean;
  infoPlanungsjahr: boolean;
  infoVerhaltnisErfasst: boolean;
  infoVertragVerkurzt: boolean;
  infoVertragVerlangert: boolean;
  infoAbgebrochen: boolean;
  infoAuflagen: boolean;
  infoRechnungsbetrag: boolean;
  infoworkNote: boolean;
  infointernalcomments: boolean;
  infofeedback: boolean;
  infoDirektanstellung: boolean;
  zeitplanungEinschreibedatum: boolean;
  zeitplanungLehrauftragZielgesprächOK: boolean;
  zeitplanungAbgabeZielgesprächSoll: boolean;
  zeitplanungAbgabeZielgesprächIst: boolean;
  zeitplanungVersandQualiSoll: boolean;
  zeitplanungVersandQualiIst: boolean;
  zeitplanungLehrauftragQualiOK: boolean;
  zeitplanungAbgabeQualiSoll: boolean;
  zeitplanungAbgabeQualiIst: boolean;
  zeitplanungAbgabeQualiOk: boolean;
  zeitplanungGutschein: boolean;
  zeitplanungErhaltBeurteilungsbogenSoll: boolean;
  zeitplanungErhaltBeurteilungsbogenIst: boolean;
  zeitplanungVertragSoll: boolean;
  zeitplanungVertragIst: boolean;
  zeitplanungPraktikumsbestatigungSoll: boolean;
  zeitplanungPraktikumsbestatigungIst: boolean;
  zeitplanungVersandBestatigung: boolean;
  zeitplanungUnterlagenNichtVollstandig: boolean;
  zeitplanungBemerkungen: boolean;
  bewertungUploads: boolean;
  bewertungAbsenzen: boolean;
  bewertungAnzahlStundenEffektiv: boolean;
  bewertungNote: boolean;
  bewertungDiskretisierung: boolean;
  bewertunggradeDate: boolean;
  bewerunggradeFixed: boolean;
  bewertungBewertung: boolean;
  bewertungbestandenRadioButtons: boolean;
  bewertungFormativeEndbeurteilung: boolean;
  bewertungBemerkungen: boolean;
  bewertungRegistered: boolean;
  startUndEndeAusfuellen: boolean;
  fieldsIPH: boolean;
  fieldsS: boolean;
  fieldsIPF: boolean;
  ausbildungsjahr: boolean;
}

export const deptFieldsInternshipDetailDefault: DeptFieldsInternshipDetail = {
  workflowstatus: false,
  selectedStudyState: false,
  cancelledInterrupted: false,
  subjectArea: false,
  zeitplanungButton: false,
  studentSpezialfall: false,
  studierendenbegleitende: false,
  orgChirAndCoRadioButtons: false,
  orgWochenbettabteilung: false,
  orgAkutsomatikRadioButtons: false,
  trainer: false,
  trainer1and2: false,
  orgFachforum: false,
  infoPraktikumsperiode: false,
  infoPraktikumsbeginn: false,
  infoVertragsbeginn: false,
  infoPraktikumsBeginnEnde: false,
  infoVertragsende: false,
  infoAnzahlStunden: false,
  infoPraktikumsende: false,
  infoAnzahlArbeitstage: false,
  infoArbeitspensum: false,
  infoPlanungsjahr: false,
  infoVerhaltnisErfasst: false,
  infoVertragVerkurzt: false,
  infoVertragVerlangert: false,
  infoAbgebrochen: false,
  infoAuflagen: false,
  infoRechnungsbetrag: false,
  infoworkNote: true,
  infointernalcomments: true,
  infofeedback: true,
  infoDirektanstellung: false,
  zeitplanungEinschreibedatum: false,
  zeitplanungLehrauftragZielgesprächOK: false,
  zeitplanungAbgabeZielgesprächSoll: false,
  zeitplanungAbgabeZielgesprächIst: false,
  zeitplanungVersandQualiSoll: false,
  zeitplanungVersandQualiIst: false,
  zeitplanungLehrauftragQualiOK: false,
  zeitplanungAbgabeQualiSoll: false,
  zeitplanungAbgabeQualiIst: false,
  zeitplanungAbgabeQualiOk: false,
  zeitplanungGutschein: false,
  zeitplanungErhaltBeurteilungsbogenSoll: false,
  zeitplanungErhaltBeurteilungsbogenIst: false,
  zeitplanungVertragSoll: false,
  zeitplanungVertragIst: false,
  zeitplanungPraktikumsbestatigungSoll: false,
  zeitplanungPraktikumsbestatigungIst: false,
  zeitplanungVersandBestatigung: false,
  zeitplanungUnterlagenNichtVollstandig: false,
  zeitplanungBemerkungen: false,
  bewertungUploads: false,
  bewertungAbsenzen: false,
  bewertungAnzahlStundenEffektiv: false,
  bewertungNote: false,
  bewertungDiskretisierung: true,
  bewertunggradeDate: false,
  bewerunggradeFixed: false,
  bewertungBewertung: false,
  bewertungbestandenRadioButtons: false,
  bewertungFormativeEndbeurteilung: false,
  bewertungBemerkungen: false,
  bewertungRegistered: false,
  startUndEndeAusfuellen: false,
  fieldsIPH: false,
  fieldsS: false,
  fieldsIPF: false,
  ausbildungsjahr: false,
};

export const deptFieldsInternshipDetailShowAll: DeptFieldsInternshipDetail = {
  workflowstatus: true,
  selectedStudyState: true,
  cancelledInterrupted: true,
  subjectArea: true,
  zeitplanungButton: true,
  studentSpezialfall: true,
  studierendenbegleitende: true,
  orgChirAndCoRadioButtons: true,
  orgWochenbettabteilung: true,
  orgAkutsomatikRadioButtons: true,
  trainer: true,
  trainer1and2: true,
  orgFachforum: true,
  infoPraktikumsperiode: true,
  infoPraktikumsbeginn: true,
  infoVertragsbeginn: true,
  infoPraktikumsBeginnEnde: false,
  infoVertragsende: true,
  infoAnzahlStunden: true,
  infoPraktikumsende: true,
  infoAnzahlArbeitstage: true,
  infoArbeitspensum: true,
  infoPlanungsjahr: true,
  infoVerhaltnisErfasst: true,
  infoVertragVerkurzt: true,
  infoVertragVerlangert: true,
  infoAbgebrochen: true,
  infoAuflagen: true,
  infoRechnungsbetrag: true,
  infoworkNote: true,
  infointernalcomments: true,
  infofeedback: true,
  infoDirektanstellung: true,
  zeitplanungEinschreibedatum: true,
  zeitplanungLehrauftragZielgesprächOK: true,
  zeitplanungAbgabeZielgesprächSoll: true,
  zeitplanungAbgabeZielgesprächIst: true,
  zeitplanungVersandQualiSoll: true,
  zeitplanungVersandQualiIst: true,
  zeitplanungLehrauftragQualiOK: true,
  zeitplanungAbgabeQualiSoll: true,
  zeitplanungAbgabeQualiIst: true,
  zeitplanungAbgabeQualiOk: true,
  zeitplanungGutschein: true,
  zeitplanungErhaltBeurteilungsbogenSoll: true,
  zeitplanungErhaltBeurteilungsbogenIst: true,
  zeitplanungVertragSoll: true,
  zeitplanungVertragIst: true,
  zeitplanungPraktikumsbestatigungSoll: true,
  zeitplanungPraktikumsbestatigungIst: true,
  zeitplanungVersandBestatigung: true,
  zeitplanungUnterlagenNichtVollstandig: true,
  zeitplanungBemerkungen: true,
  bewertungUploads: true,
  bewertungAbsenzen: true,
  bewertungAnzahlStundenEffektiv: true,
  bewertungNote: true,
  bewertungDiskretisierung: true,
  bewertunggradeDate: true,
  bewerunggradeFixed: false,
  bewertungBewertung: true,
  bewertungbestandenRadioButtons: true,
  bewertungFormativeEndbeurteilung: true,
  bewertungBemerkungen: true,
  bewertungRegistered: true,
  startUndEndeAusfuellen: true,
  fieldsIPH: true,
  fieldsS: true,
  fieldsIPF: false,
  ausbildungsjahr: true,
};

export const deptFieldsInternshipDetailIER: DeptFieldsInternshipDetail = {
  workflowstatus: true,
  selectedStudyState: false,
  cancelledInterrupted: false,
  subjectArea: true,
  zeitplanungButton: false,
  studentSpezialfall: false,
  studierendenbegleitende: true,
  orgChirAndCoRadioButtons: false,
  orgWochenbettabteilung: false,
  orgAkutsomatikRadioButtons: false,
  trainer: false,
  trainer1and2: true,
  orgFachforum: true,
  infoPraktikumsperiode: false,
  infoPraktikumsbeginn: true,
  infoVertragsbeginn: false,
  infoPraktikumsBeginnEnde: true,
  infoVertragsende: false,
  infoAnzahlStunden: false,
  infoPraktikumsende: true,
  infoAnzahlArbeitstage: true,
  infoArbeitspensum: true,
  infoPlanungsjahr: true,
  infoVerhaltnisErfasst: false,
  infoVertragVerkurzt: false,
  infoVertragVerlangert: false,
  infoAbgebrochen: false,
  infoAuflagen: false,
  infoRechnungsbetrag: true,
  infoworkNote: true,
  infointernalcomments: true,
  infofeedback: false,
  infoDirektanstellung: true,
  zeitplanungEinschreibedatum: false,
  zeitplanungLehrauftragZielgesprächOK: false,
  zeitplanungAbgabeZielgesprächSoll: false,
  zeitplanungAbgabeZielgesprächIst: false,
  zeitplanungVersandQualiSoll: false,
  zeitplanungVersandQualiIst: false,
  zeitplanungLehrauftragQualiOK: false,
  zeitplanungAbgabeQualiSoll: false,
  zeitplanungAbgabeQualiIst: false,
  zeitplanungAbgabeQualiOk: false,
  zeitplanungGutschein: false,
  zeitplanungErhaltBeurteilungsbogenSoll: true,
  zeitplanungErhaltBeurteilungsbogenIst: true,
  zeitplanungVertragSoll: true,
  zeitplanungVertragIst: true,
  zeitplanungPraktikumsbestatigungSoll: false,
  zeitplanungPraktikumsbestatigungIst: false,
  zeitplanungVersandBestatigung: false,
  zeitplanungUnterlagenNichtVollstandig: false,
  zeitplanungBemerkungen: false,
  bewertungUploads: true,
  bewertungAbsenzen: true,
  bewertungAnzahlStundenEffektiv: false,
  bewertungNote: true,
  bewertungDiskretisierung: true,
  bewertunggradeDate: true,
  bewerunggradeFixed: false,
  bewertungBewertung: false,
  bewertungbestandenRadioButtons: true,
  bewertungFormativeEndbeurteilung: false,
  bewertungBemerkungen: false,
  bewertungRegistered: true,
  startUndEndeAusfuellen: true,
  fieldsIPH: false,
  fieldsS: false,
  fieldsIPF: false,
  ausbildungsjahr: false,
};

export const deptFieldsInternshipDetailIHB: DeptFieldsInternshipDetail = {
  workflowstatus: true,
  selectedStudyState: false,
  cancelledInterrupted: false,
  subjectArea: true,
  zeitplanungButton: true,
  studentSpezialfall: false,
  studierendenbegleitende: true,
  orgChirAndCoRadioButtons: false,
  orgWochenbettabteilung: true,
  orgAkutsomatikRadioButtons: false,
  trainer: true,
  trainer1and2: false,
  orgFachforum: false,
  infoPraktikumsperiode: true,
  infoPraktikumsbeginn: true,
  infoVertragsbeginn: false,
  infoPraktikumsBeginnEnde: true,
  infoVertragsende: false,
  infoAnzahlStunden: false,
  infoPraktikumsende: true,
  infoAnzahlArbeitstage: false,
  infoArbeitspensum: true,
  infoPlanungsjahr: true,
  infoVerhaltnisErfasst: false,
  infoVertragVerkurzt: false,
  infoVertragVerlangert: false,
  infoAbgebrochen: false,
  infoAuflagen: true,
  infoRechnungsbetrag: false,
  infoworkNote: true,
  infointernalcomments: true,
  infofeedback: false,
  infoDirektanstellung: false,
  zeitplanungEinschreibedatum: false,
  zeitplanungLehrauftragZielgesprächOK: false,
  zeitplanungAbgabeZielgesprächSoll: false,
  zeitplanungAbgabeZielgesprächIst: false,
  zeitplanungVersandQualiSoll: false,
  zeitplanungVersandQualiIst: false,
  zeitplanungLehrauftragQualiOK: false,
  zeitplanungAbgabeQualiSoll: false,
  zeitplanungAbgabeQualiIst: false,
  zeitplanungAbgabeQualiOk: false,
  zeitplanungGutschein: false,
  zeitplanungErhaltBeurteilungsbogenSoll: true,
  zeitplanungErhaltBeurteilungsbogenIst: true,
  zeitplanungVertragSoll: false,
  zeitplanungVertragIst: false,
  zeitplanungPraktikumsbestatigungSoll: false,
  zeitplanungPraktikumsbestatigungIst: false,
  zeitplanungVersandBestatigung: false,
  zeitplanungUnterlagenNichtVollstandig: false,
  zeitplanungBemerkungen: false,
  bewertungUploads: false,
  bewertungAbsenzen: true,
  bewertungAnzahlStundenEffektiv: false,
  bewertungNote: true,
  bewertungDiskretisierung: true,
  bewertunggradeDate: false,
  bewerunggradeFixed: false,
  bewertungBewertung: true,
  bewertungbestandenRadioButtons: true,
  bewertungFormativeEndbeurteilung: false,
  bewertungBemerkungen: false,
  bewertungRegistered: false,
  startUndEndeAusfuellen: true,
  fieldsIPH: false,
  fieldsS: false,
  fieldsIPF: false,
  ausbildungsjahr: true,
};

export const deptFieldsInternshipDetailIPF: DeptFieldsInternshipDetail = {
  workflowstatus: true,
  selectedStudyState: false,
  cancelledInterrupted: false,
  subjectArea: true,
  zeitplanungButton: false,
  studentSpezialfall: false,
  studierendenbegleitende: false,
  orgChirAndCoRadioButtons: false,
  orgWochenbettabteilung: false,
  orgAkutsomatikRadioButtons: true,
  trainer: false,
  trainer1and2: false,
  orgFachforum: false,
  infoPraktikumsperiode: false,
  infoPraktikumsbeginn: false,
  infoVertragsbeginn: true,
  infoPraktikumsBeginnEnde: false,
  infoVertragsende: true,
  infoAnzahlStunden: false,
  infoPraktikumsende: false,
  infoAnzahlArbeitstage: false,
  infoArbeitspensum: false,
  infoPlanungsjahr: true,
  infoVerhaltnisErfasst: false,
  infoVertragVerkurzt: false,
  infoVertragVerlangert: false,
  infoAbgebrochen: false,
  infoAuflagen: false,
  infoRechnungsbetrag: false,
  infoworkNote: true,
  infointernalcomments: true,
  infofeedback: true,
  infoDirektanstellung: false,
  zeitplanungEinschreibedatum: false,
  zeitplanungLehrauftragZielgesprächOK: false,
  zeitplanungAbgabeZielgesprächSoll: false,
  zeitplanungAbgabeZielgesprächIst: false,
  zeitplanungVersandQualiSoll: false,
  zeitplanungVersandQualiIst: false,
  zeitplanungLehrauftragQualiOK: false,
  zeitplanungAbgabeQualiSoll: true,
  zeitplanungAbgabeQualiIst: true,
  zeitplanungAbgabeQualiOk: true,
  zeitplanungGutschein: false,
  zeitplanungErhaltBeurteilungsbogenSoll: false,
  zeitplanungErhaltBeurteilungsbogenIst: false,
  zeitplanungVertragSoll: false,
  zeitplanungVertragIst: false,
  zeitplanungPraktikumsbestatigungSoll: false,
  zeitplanungPraktikumsbestatigungIst: false,
  zeitplanungVersandBestatigung: false,
  zeitplanungUnterlagenNichtVollstandig: false,
  zeitplanungBemerkungen: false,
  bewertungUploads: true,
  bewertungAbsenzen: true,
  bewertungAnzahlStundenEffektiv: false,
  bewertungNote: true,
  bewertungDiskretisierung: true,
  bewertunggradeDate: false,
  bewerunggradeFixed: false,
  bewertungBewertung: false,
  bewertungbestandenRadioButtons: true,
  bewertungFormativeEndbeurteilung: false,
  bewertungBemerkungen: false,
  bewertungRegistered: true,
  startUndEndeAusfuellen: true,
  fieldsIPH: false,
  fieldsS: false,
  fieldsIPF: true,
  ausbildungsjahr: false,
};

export const deptFieldsInternshipDetailIPT: DeptFieldsInternshipDetail = {
  workflowstatus: false,
  selectedStudyState: false,
  cancelledInterrupted: false,
  subjectArea: true,
  zeitplanungButton: false,
  studentSpezialfall: true,
  studierendenbegleitende: false,
  orgChirAndCoRadioButtons: true,
  orgWochenbettabteilung: false,
  orgAkutsomatikRadioButtons: false,
  trainer: false,
  trainer1and2: false,
  orgFachforum: false,
  infoPraktikumsperiode: true,
  infoPraktikumsbeginn: true,
  infoVertragsbeginn: false,
  infoPraktikumsBeginnEnde: true,
  infoVertragsende: false,
  infoAnzahlStunden: false,
  infoPraktikumsende: true,
  infoAnzahlArbeitstage: false,
  infoArbeitspensum: true,
  infoPlanungsjahr: true,
  infoVerhaltnisErfasst: false,
  infoVertragVerkurzt: false,
  infoVertragVerlangert: false,
  infoAbgebrochen: false,
  infoAuflagen: false,
  infoRechnungsbetrag: false,
  infoworkNote: true,
  infointernalcomments: true,
  infofeedback: true,
  infoDirektanstellung: false,
  zeitplanungEinschreibedatum: false,
  zeitplanungLehrauftragZielgesprächOK: false,
  zeitplanungAbgabeZielgesprächSoll: false,
  zeitplanungAbgabeZielgesprächIst: false,
  zeitplanungVersandQualiSoll: false,
  zeitplanungVersandQualiIst: false,
  zeitplanungLehrauftragQualiOK: false,
  zeitplanungAbgabeQualiSoll: false,
  zeitplanungAbgabeQualiIst: false,
  zeitplanungAbgabeQualiOk: false,
  zeitplanungGutschein: false,
  zeitplanungErhaltBeurteilungsbogenSoll: false,
  zeitplanungErhaltBeurteilungsbogenIst: false,
  zeitplanungVertragSoll: false,
  zeitplanungVertragIst: false,
  zeitplanungPraktikumsbestatigungSoll: true,
  zeitplanungPraktikumsbestatigungIst: true,
  zeitplanungVersandBestatigung: true,
  zeitplanungUnterlagenNichtVollstandig: true,
  zeitplanungBemerkungen: true,
  bewertungUploads: true,
  bewertungAbsenzen: true,
  bewertungAnzahlStundenEffektiv: false,
  bewertungNote: true,
  bewertungDiskretisierung: true,
  bewertunggradeDate: false,
  bewerunggradeFixed: false,
  bewertungBewertung: false,
  bewertungbestandenRadioButtons: true,
  bewertungFormativeEndbeurteilung: true,
  bewertungBemerkungen: true,
  bewertungRegistered: true,
  startUndEndeAusfuellen: true,
  fieldsIPH: false,
  fieldsS: false,
  fieldsIPF: false,
  ausbildungsjahr: true,
};

export const deptFieldsInternshipDetailS: DeptFieldsInternshipDetail = {
  workflowstatus: true,
  selectedStudyState: true,
  cancelledInterrupted: true,
  subjectArea: false,
  zeitplanungButton: true,
  studentSpezialfall: false,
  studierendenbegleitende: true,
  orgChirAndCoRadioButtons: false,
  orgWochenbettabteilung: false,
  orgAkutsomatikRadioButtons: false,
  trainer: true,
  trainer1and2: false,
  orgFachforum: false,
  infoPraktikumsperiode: false,
  infoPraktikumsbeginn: false,
  infoVertragsbeginn: true,
  infoPraktikumsBeginnEnde: false,
  infoVertragsende: true,
  infoAnzahlStunden: true,
  infoPraktikumsende: false,
  infoAnzahlArbeitstage: false,
  infoArbeitspensum: true,
  infoPlanungsjahr: false, // ZHAW-872
  infoVerhaltnisErfasst: true,
  infoVertragVerkurzt: true,
  infoVertragVerlangert: true,
  infoAbgebrochen: true,
  infoAuflagen: false,
  infoRechnungsbetrag: false,
  infoworkNote: true,
  infointernalcomments: true,
  infofeedback: false,
  infoDirektanstellung: false,
  zeitplanungEinschreibedatum: true,
  zeitplanungLehrauftragZielgesprächOK: true,
  zeitplanungAbgabeZielgesprächSoll: true,
  zeitplanungAbgabeZielgesprächIst: true,
  zeitplanungVersandQualiSoll: true,
  zeitplanungVersandQualiIst: true,
  zeitplanungLehrauftragQualiOK: true,
  zeitplanungAbgabeQualiSoll: true,
  zeitplanungAbgabeQualiIst: true,
  zeitplanungAbgabeQualiOk: false,
  zeitplanungGutschein: true,
  zeitplanungErhaltBeurteilungsbogenSoll: false,
  zeitplanungErhaltBeurteilungsbogenIst: false,
  zeitplanungVertragSoll: false,
  zeitplanungVertragIst: false,
  zeitplanungPraktikumsbestatigungSoll: false,
  zeitplanungPraktikumsbestatigungIst: false,
  zeitplanungVersandBestatigung: false,
  zeitplanungUnterlagenNichtVollstandig: false,
  zeitplanungBemerkungen: false,
  bewertungUploads: false,
  bewertungAbsenzen: false,
  bewertungAnzahlStundenEffektiv: true,
  bewertungNote: true,
  bewertungDiskretisierung: true,
  bewertunggradeDate: true,
  bewerunggradeFixed: false,
  bewertungBewertung: true,
  bewertungbestandenRadioButtons: true,
  bewertungFormativeEndbeurteilung: false,
  bewertungBemerkungen: false,
  bewertungRegistered: false,
  startUndEndeAusfuellen: false,
  fieldsIPH: false,
  fieldsS: true,
  fieldsIPF: false,
  ausbildungsjahr: false,
};

export const deptFieldsInternshipDetailIPH: DeptFieldsInternshipDetail = {
  workflowstatus: true,
  selectedStudyState: false,
  cancelledInterrupted: false,
  subjectArea: false,
  zeitplanungButton: true,
  studentSpezialfall: false,
  studierendenbegleitende: true,
  orgChirAndCoRadioButtons: false,
  orgWochenbettabteilung: false,
  orgAkutsomatikRadioButtons: false,
  trainer: true,
  trainer1and2: false,
  orgFachforum: false,
  infoPraktikumsperiode: false,
  infoPraktikumsbeginn: false,
  infoVertragsbeginn: true,
  infoPraktikumsBeginnEnde: false,
  infoVertragsende: true,
  infoAnzahlStunden: true,
  infoPraktikumsende: false,
  infoAnzahlArbeitstage: false,
  infoArbeitspensum: true,
  infoPlanungsjahr: true, //ZHAW-872
  infoVerhaltnisErfasst: true,
  infoVertragVerkurzt: true,
  infoVertragVerlangert: true,
  infoAbgebrochen: true,
  infoAuflagen: false,
  infoRechnungsbetrag: false,
  infoworkNote: true,
  infointernalcomments: true,
  infofeedback: false,
  infoDirektanstellung: false,
  zeitplanungEinschreibedatum: false,
  zeitplanungLehrauftragZielgesprächOK: true,
  zeitplanungAbgabeZielgesprächSoll: true,
  zeitplanungAbgabeZielgesprächIst: true,
  zeitplanungVersandQualiSoll: true,
  zeitplanungVersandQualiIst: true,
  zeitplanungLehrauftragQualiOK: true,
  zeitplanungAbgabeQualiSoll: false,
  zeitplanungAbgabeQualiIst: false,
  zeitplanungAbgabeQualiOk: false,
  zeitplanungGutschein: true,
  zeitplanungErhaltBeurteilungsbogenSoll: false,
  zeitplanungErhaltBeurteilungsbogenIst: false,
  zeitplanungVertragSoll: false,
  zeitplanungVertragIst: false,
  zeitplanungPraktikumsbestatigungSoll: false,
  zeitplanungPraktikumsbestatigungIst: false,
  zeitplanungVersandBestatigung: false,
  zeitplanungUnterlagenNichtVollstandig: false,
  zeitplanungBemerkungen: false,
  bewertungUploads: false,
  bewertungAbsenzen: false,
  bewertungAnzahlStundenEffektiv: true,
  bewertungNote: true,
  bewertungDiskretisierung: true,
  bewertunggradeDate: false,
  bewerunggradeFixed: false,
  bewertungBewertung: true,
  bewertungbestandenRadioButtons: true,
  bewertungFormativeEndbeurteilung: false,
  bewertungBemerkungen: false,
  bewertungRegistered: true,
  startUndEndeAusfuellen: false,
  fieldsIPH: true,
  fieldsS: false,
  fieldsIPF: false,
  ausbildungsjahr: false,
};

export const deptFieldsInternshipDetailN: DeptFieldsInternshipDetail = {
  workflowstatus: false,
  selectedStudyState: false,
  cancelledInterrupted: false,
  subjectArea: true,
  zeitplanungButton: false,
  studentSpezialfall: false,
  studierendenbegleitende: false,
  orgChirAndCoRadioButtons: false,
  orgWochenbettabteilung: false,
  orgAkutsomatikRadioButtons: false,
  trainer: false,
  trainer1and2: false,
  orgFachforum: false,
  infoPraktikumsperiode: false,
  infoPraktikumsbeginn: false,
  infoVertragsbeginn: false,
  infoPraktikumsBeginnEnde: false,
  infoVertragsende: false,
  infoAnzahlStunden: false,
  infoPraktikumsende: false,
  infoAnzahlArbeitstage: false,
  infoArbeitspensum: false,
  infoPlanungsjahr: true,
  infoVerhaltnisErfasst: false,
  infoVertragVerkurzt: false,
  infoVertragVerlangert: false,
  infoAbgebrochen: false,
  infoAuflagen: false,
  infoRechnungsbetrag: false,
  infoworkNote: true,
  infointernalcomments: true,
  infofeedback: true,
  infoDirektanstellung: false,
  zeitplanungEinschreibedatum: false,
  zeitplanungLehrauftragZielgesprächOK: false,
  zeitplanungAbgabeZielgesprächSoll: false,
  zeitplanungAbgabeZielgesprächIst: false,
  zeitplanungVersandQualiSoll: false,
  zeitplanungVersandQualiIst: false,
  zeitplanungLehrauftragQualiOK: false,
  zeitplanungAbgabeQualiSoll: false,
  zeitplanungAbgabeQualiIst: false,
  zeitplanungAbgabeQualiOk: false,
  zeitplanungGutschein: false,
  zeitplanungErhaltBeurteilungsbogenSoll: false,
  zeitplanungErhaltBeurteilungsbogenIst: false,
  zeitplanungVertragSoll: false,
  zeitplanungVertragIst: false,
  zeitplanungPraktikumsbestatigungSoll: false,
  zeitplanungPraktikumsbestatigungIst: false,
  zeitplanungVersandBestatigung: false,
  zeitplanungUnterlagenNichtVollstandig: false,
  zeitplanungBemerkungen: false,
  bewertungUploads: true,
  bewertungAbsenzen: false,
  bewertungAnzahlStundenEffektiv: false,
  bewertungNote: true,
  bewertungDiskretisierung: true,
  bewertunggradeDate: false,
  bewerunggradeFixed: false,
  bewertungBewertung: false,
  bewertungbestandenRadioButtons: true,
  bewertungFormativeEndbeurteilung: false,
  bewertungBemerkungen: false,
  bewertungRegistered: true,
  startUndEndeAusfuellen: true,
  fieldsIPH: false,
  fieldsS: false,
  fieldsIPF: false,
  ausbildungsjahr: false,
};
